<template>
    <div class="about" id="quotaReviewList">
          <div style="text-align: left;margin-bottom: 20px;">
            <el-button type="primary" icon="el-icon-download" @click="exportTable">导出</el-button>
          </div>
          <div style="display: flex;justify-content: space-between;">
            <span>发薪汇总表编号: <b>{{$route.query.summaryNo}}</b></span>
            <span>创建时间：<b>{{$route.query.createTime}}</b></span>
            <span>总记录：<b>{{total}}条</b></span>
          </div>
          <div>
              <el-table
                :data="tableData"
                :header-cell-style="tableHeader"
                :cell-style="tableCell"
                border
                fit
                style="width: 100%">
                <el-table-column  type="index"  label="序号" />
                <el-table-column prop="name" label="姓名" :show-overflow-tooltip="true" />
                <el-table-column prop="bankCard" label="银行卡" :show-overflow-tooltip="true" />
                <el-table-column prop="idno" label="身份证号" :show-overflow-tooltip="true" />
                <el-table-column prop="mobile"  label="手机号码" :show-overflow-tooltip="true"  /> 
                <el-table-column prop="remark" width="500px" label="异常原因" :show-overflow-tooltip="true" />
                <!-- <el-table-column prop="createTime" label="创建时间" /> -->
              </el-table>
              <el-pagination
                id="page"
                background
                @size-change="sizeChange"
                @current-change="currentChange"
                @prev-click="currentChange"
                @next-click="currentChange"
                :page-sizes="[5, 10, 20]"
                :page-size="queryForm.pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
              </el-pagination>
          </div>
      
    </div>
</template>
  
<script>
  export default {
      data() {
        return {
          summaryStateList: null,
          tableData: [],
          queryForm: {
            salarySummarizingRecordsId: '',
            pageNo: 1,
            pageSize: 10
          },
          
          itemList:[],
         
          total:0,
          totals:0,
        }
      },
      async created(){
        this.queryForm.salarySummarizingRecordsId = this.$route.query.summid;
        await this.loadTable();
        // this.findDictionariesItemList(this.diction);
      },
      methods: {
        
      // 加载银行流水表格
      async loadTable(){
        await this.api.salaryApi.listAuthFailedInfo(this.queryForm).then(res =>{
          res = res.data;
          if(res.code == 200){
            this.tableData = res.data.records;
            this.total=res.data.total;
          }else{
            this.tableData =  null;
            this.total = 0;
            this.$message.error(res.msg);
          }
        })
      },

      async exportTable(){
        console.log("导出下载");
        await this.api.salaryApi.downloadSalaryErrorInfo({salarySummarizingId:this.queryForm.salarySummarizingRecordsId}).then(res =>{
          console.log(res)
          let fileNameEncode = "校验异常信息.xlsx";
          const link = document.createElement("a");
          let blob = new Blob([res.data], { type: "application/vnd.ms-excel"});
          console.log(blob);
          link.style.display = "none";
          link.href = URL.createObjectURL(blob);
          link.setAttribute("download",  decodeURIComponent(fileNameEncode));
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
      },
     
      // async findDictionariesItemList(param) {
      //   let response = await this.api.platformApi.findDictionariesItemList(param);
      //   console.log("数据字典", response);
      //   this.itemList = response.data.data;
      // },

        // 条件查询
        onQuery() {
          this.loadTable();
        },
        // 重置
        onReset(){
          this.queryForm.idno='';
          this.queryForm.name='';
          this.loadTable();
        },
        
        //current-change
        currentChange(page){
          this.queryForm.pageNo = page;
          this.loadTable();
        },
        
  
        // size-change
        sizeChange(size){
          this.queryForm.pageSize = size;
          this.loadTable();
        },
        
        tableHeader(column) {
        return "height:36px;background-color:#fafafa;color:#333333;padding:0";
        },
        tableCell(column) {
          return "height:40px;background-color:#fafafa;color:#000000;padding:0";
        },
        //除法函数
        accDiv(arg1, arg2){
           if(arg1!=null){
            var t1 = 0, t2 = 0, r1, r2;
            try {
              t1 = arg1.toString().split(".")[1].length;
            }
            catch (e) {
            }
            try {
              t2 = arg2.toString().split(".")[1].length;
            }
            catch (e) {
            }
            r1 = Number(arg1.toString().replace(".", ""));
            r2 = Number(arg2.toString().replace(".", ""));
            return (r1 / r2) * Math.pow(10, t2 - t1);
           }
           else{
            return null
           }
          }
      }
  }
</script>
  
<style scoped>
  
  .hzdate{
    display: flex;
    margin: 180px 0px;
    justify-content: center;
    justify-content: space-evenly;
  }
  
  #page {
    float: right;
    margin-top: 15px;
  }
  
  #quotaReviewList {
    overflow: hidden;
    background-color: white;
    padding: 15px 20px 100px;
  }
  
  >>> .el-dialog__body {
    border-top: 1px solid rgba(228, 228, 228, 1);
    border-bottom: 1px solid rgb(228, 228, 228, 1);
    padding-left: 0;
    padding-right: 0;
  }
  
  >>> .el-drawer.rtl {
    overflow: scroll;
  }
  
  .wageadjustment{
    margin: 0 50px;
  }
  
  </style>
  
  